import { Component } from "react";

import "../Blog.css";


export class Mouseless extends Component<{}> {
    public render() {
        return (
            <div className="Blog-Post">

            </div>
        )

    }
}